import React from "react";

const SidebarData = [
  {
    title: "Leaderboard",
    path: "/hate/main",
    icon: <i className="material-icons-outlined">leaderboard</i>
  },
  {
    title: "받은 악플함",
    path: "/list/box",
    icon: <i className="material-icons-outlined">email</i>,
  },
];

export default SidebarData;
