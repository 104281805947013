import React, { useEffect } from "react";
import "./mail.css";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deletePost } from "../../redux/actions/others";

import Badge from "@mui/material/Badge";
import { siteImage } from "../site/siteImage";
import { localTime } from "../../function";
import { getHateDetail } from "../../redux/actions/api_user";
import useApi from "../hooks/useApi";
import LoadFrame from "../loading/LoadFrame";

const HighlightText = ({ text, aliasList, className }) => {
  if (!text || !aliasList || aliasList.length === 0) return text;

  let neededAliasList = aliasList.filter(x => x.type==="IA").map(x => x.name)

  // 여러 단어를 |(OR)로 연결한 정규식 생성
  const regex = new RegExp(`(${neededAliasList.join("|")})`, "gi");

  const parts = text.split(regex);

  return (
    <div className={className}>
      {parts.map((part, index) =>
        neededAliasList.some((word) => word.toLowerCase() === part.toLowerCase()) ? (
          <span key={index} style={{ color: "red", fontWeight: "bold" }}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </div>
  );
};


const MailPostModal = () => {
  const others = useSelector((state) => state.Others);
  const dispatch = useDispatch();
  const [loading, detailData, error, getData] = useApi(getHateDetail);

  useEffect(() => {
    if (others.modal) {
      getData(others.post);
    }
  }, [others]);

  return (
    <Modal
      className="modal"
      show={others.modal}
      onHide={() => dispatch(deletePost())}
    >
      <Modal.Header closeButton>
        <div className="d-row">
          <div className="col-3">
            <Badge
              badgeContent={others.post?.board_dc_type === "M" ? "M" : 0}
              color="primary"
            >
              <img
                src={siteImage(others.post.site)}
                className="logo"
                alt="site"
              />
            </Badge>
          </div>
          <div className="col-9 description">
            {others.post?.board_description}
          </div>
          <div>
            <HighlightText className="title" text={others.post.title} aliasList={others.alias}/>
            <div className="nickname">{others.post.nickname}</div>
            <div className="site">{others.post.site}</div>
          </div>

          <div className="date">
            {localTime(others.post.timestamp || others.post.written_at)}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modal__content">
          <div className="content">
            {others.post?.content && <HighlightText text={others.post.content} aliasList={others.alias}/>}
            {others.post?.type === "comment" && (
              <LoadFrame loading={loading}>
                <div className="comment-parent">
                  <div className="parent">원 게시글 내용</div>
                  {detailData?.hate_post_title && (
                    <HighlightText className="parent-title" text={detailData.hate_post_title} aliasList={others.alias} />
                      
                  )}
                  {detailData?.hate_post_content && (
                    <HighlightText className="parent-content" text={detailData.hate_post_content} aliasList={others.alias} />
                  )}
                </div>
              </LoadFrame>
            )}
          </div>
        </div>
      </Modal.Body>
      <LoadFrame loading={loading}>
        <Modal.Footer>
          <p className="category">
            {others.post.category || detailData?.tag_name}
          </p>
          <a href={detailData?.url} target="_blank" rel="noreferrer">
            원게시글 링크
          </a>
          {detailData?.has_comment_url && (
            <a href={detailData.comment_url} target="_blank" rel="noreferrer">
              원댓글 링크
            </a>
          )}
          <a href={detailData?.screenshot} target="_blank" rel="noreferrer">
            스크린샷
          </a>
        </Modal.Footer>
      </LoadFrame>
    </Modal>
  );
};

export default MailPostModal;
