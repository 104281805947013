import AdminPage from "./pages/admin/admin";
import SigninPage from "./pages/shared/signin/SigninPage";
import { LoadElement } from "./components/loading/LoadFrame";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ScrollToTop from "./components/ScrollToTop";
import RedirectRoutes from "./RedirectRoutes";
import useApi from "./components/hooks/useApi";

import { checkAuth } from "./redux/actions/auth";
import RouteTracker from "./ga4/RouteTracker";
// import ReactGA from "react-ga4";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./pages/shared/error/ErrorPage";
import Profile from "./pages/admin/profile";
import { getClients } from "./redux/actions/api_user";
import { setProfiles } from "./redux/actions/others";

const routePath = {
  admin: [{ path: "/admin/*", component: AdminPage }],
  customer: [{ path: "/admin/*", component: AdminPage }],
  anonymous: [{ path: "/signin", component: SigninPage }],
};

const SecureRoutes = () => {
  const dispatch = useDispatch();

  const [role, setRole] = useState("anonymous");
  const auth = useSelector((state) => state.auth);

  const [loading, resolved, error, checkAuthAction] = useApi(checkAuth);

  useEffect(() => {
    if (auth?.token !== null) {
      checkAuthAction();
    }
  }, []);

  const [clientsLoading, clients, clientError, getClientsAction] =
    useApi(getClients);
  useEffect(() => {
    if (auth.isAuthenticated) {
      if (!clientsLoading) {
        getClientsAction();
      }
    }
  }, [resolved, auth]);
  useEffect(() => {
    if (clients && !clientError) {
      dispatch(setProfiles(clients));
    }
  }, [clients, clientError]);

  useEffect(() => {
    if (auth && auth.user && auth.isAuthenticated) {
      setRole(auth.user.role || "anonymous");
      // let uid = auth.user?.id?.toString();
      //   ReactGA.set({
      //     userId: uid ? "UID_" + uid : undefined,
      //   });
      // } else {
      //   setRole("anonymous");
      //   ReactGA.set({
      //     userId: undefined,
      //   });
    } else {
      setRole("anonymous");
    }
  }, [auth, auth.isAuthenticated]);

  const genRoutes = (role) => {
    const routeData = routePath[role];
    if (routeData) {
      return routeData.map((data) => {
        let ComponentToRender = data["component"];
        return (
          <Route
            exact
            key={data["path"]}
            path={data["path"]}
            element={<ComponentToRender />}
          />
        );
      });
    }
  };

  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <ScrollToTop />
        <RouteTracker userId={auth?.user?.id} />
        <Routes>
          {genRoutes(role)}
          {auth && auth.isAuthenticated && (
            <Route key="profile" path="/profile" element={<Profile />} />
          )}
          <Route
            path="*"
            element={
              loading || clientsLoading ? (
                <LoadElement main={false} />
              ) : localStorage.getItem("token") && !auth.isAuthenticated ? (
                <LoadElement main={false} />
              ) : (
                <RedirectRoutes role={role} />
              )
            }
          />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default SecureRoutes;
