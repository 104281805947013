import React from "react";
import LoadFrame from "../loading/LoadFrame";
import "./status-card.css";
import "./statuscard.css";
import { ErrorBoundary } from "react-error-boundary";
import ApiErrorFrame from "../error/ApiErrorFrame";

const StatusCard = ({ title, value, period, is_loading, error }) => {
  return (
    <div className="status-card status">
      <div className="status-card__info">
        <div className="status-card__title">{title}</div>
        <ErrorBoundary FallbackComponent={ApiErrorFrame}>
          <LoadFrame loading={is_loading} error={error}>
            {value || Number.isInteger(value) ? (
              <>
                <h4>{value}</h4>
                <span className="detail">
                  {period? period : ''}
                </span>
              </>
            ) : null}
          </LoadFrame>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default StatusCard;
