import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./user.css";
import AllStatusCard from "../../components/status-card/allStatusCard";

import ZoomChart from "../../components/chart/main/zoomGraph";
import useApi from "../../components/hooks/useApi";
import RenderWrapper from "../../components/display/renderWrapper";
import {
  getHateMainChart,
  getHateMainData,
  postDailyAnalysis
} from "../../redux/actions/api_user";

import DateRange from "../../components/datepick/dateRange";
import LoadFrame from "../../components/loading/LoadFrame";
import ApiErrorFrame from "../../components/error/ApiErrorFrame";
import { ErrorBoundary } from "react-error-boundary";

import { siteImage } from "../../components/site/siteImage";
import Badge from "@mui/material/Badge";
import { Tooltip } from "@mui/material";
import useApiSimple from "../../components/hooks/useApiSimple";

const HateMainPage = () => {
  const originDates = useSelector((state) => state.Others.dates);
  const [chartLoading, chartData, error1, getChart] = useApi(getHateMainChart);
  const [subLoading, subData, error2, getSub] = useApi(getHateMainData);
  const [postAnalysisLoading, postAnalysis] = useApiSimple(postDailyAnalysis)
  const [dataToRender, setData] = useState({});
  const [chartToRender, setChart] = useState({});

  const [resize, setResize] = useState();
  const profile = useSelector((state) => state.Others.profile);
  const auth = useSelector((state) => state.auth);

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!profile.dummy) {
      getChart(originDates.type);
    }
  }, [originDates, profile]);

  useEffect(()=> {
    if (!profile.dummy) {
      getSub();
    }
  }, [profile])

  useEffect(() => {
    if (chartData) {
      setChart(chartData.data);
    }
  }, [chartData]);

  useEffect(() => {
    if (subData) {
      setData(subData.data);
    }
  }, [subData]);

  return (
    <div>
      <div className="page-header">
        <span>Leaderboard</span>
      </div>

      <div className="page-body">
        <div>
          <div className="d-row">
            <AllStatusCard
              {...dataToRender.allstatuscard}
              loading={subLoading}
              error={error2}
            />
          </div>
        </div>
        <div>
          <div className="card full-height">
            <div className="card__header" style={{display:'flex', justifyContent:"space-between"}}>
              <h3>악플 수 변화</h3>
              <div>
                <DateRange usePickModal={false} />
              </div>
            </div>
            
            <ErrorBoundary FallbackComponent={ApiErrorFrame}>
              <LoadFrame
                loading={chartLoading || !chartToRender.range}
                error={error1}
              >
                {chartToRender.range ? (
                  <ZoomChart series={chartToRender} resize={resize} />
                ) : null}
              </LoadFrame>
            </ErrorBoundary>
          </div>
        </div>
        <div>
          <div className="d-row">
              <div className="card hate-contents">
                <div className="card__header" style={{display:'flex', justifyContent:'space-between'}}>
                  <h3>일간 대표 악플</h3>
                  <RenderWrapper condition={auth.user.role === "admin"}>
                    <button
                      onClick={postAnalysis}
                      onKeyDown={postAnalysis}
                    >
                      일간 분석 재진행
                    </button>
                  </RenderWrapper>
                </div>
                <ErrorBoundary FallbackComponent={ApiErrorFrame}>
                  <LoadFrame
                    loading={subLoading || !dataToRender.contents}
                    error={error2}
                  >
                    {dataToRender.contents?.length ? 
                    <table>
                      <tbody>
                        {dataToRender.contents.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-row first">
                                <Badge
                                  badgeContent={item.board_dc_type === "M" ? "M" : 0}
                                  color="primary"
                                >
                                  <Tooltip title={item.board_description}>
                                    <img
                                      src={siteImage(item.site_name)}
                                      className="logo"
                                      alt="site"
                                    />
                                  </Tooltip>
                                </Badge>
                                <div
                                  className="table-element hate-list-content"
                                  onClick={() => item.url ? window.open(item.url, "_blank"): null}
                                  // onKeyDown={() => showPost(index)}
                                  role="button"
                                  tabIndex={0}
                                >
                                  <div className="d-row second">
                                    <div>
                                      <span className="row-header">
                                        {item.title ? (
                                          <span className="row-title">{item.title}</span>
                                        ) : null}
                                        <span className="row-nickname">
                                          {item.nickname}
                                        </span>
                                      </span>
                                      {item.w_user_id ? (
                                        <span className="material-icons-outlined">
                                          how_to_reg
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
            
                                  <div className="row-content">{item.content}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                        : <p>🎉 어제는 새로 발견된 악플이 없습니다!</p>}
                  </LoadFrame>
                </ErrorBoundary>
              </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default HateMainPage;
