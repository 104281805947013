import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  REGISTER_FAILURE,
  AUTH_ERROR,
  CHECKAUTH_SUCCESS,
  CHANGEPW_FAIL,
  CHANGEPW_SUCCESS,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  user: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    case LOGIN_FAILURE:
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      localStorage.removeItem("lastArtist");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case CHECKAUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case REGISTER_FAILURE:
      localStorage.removeItem("token");
      localStorage.removeItem("lastArtist");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
      };
    case CHANGEPW_SUCCESS:
      alert("비밀번호 변경 성공");
      return state;
    case CHANGEPW_FAIL:
      alert("비밀번호 변경 실패");
      return state;
    default:
      return state;
  }
};

export default auth;
