import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import { signout } from "../../../redux/actions/auth";
import "./ErrorPage.css";
import useApiSimple from "../../../components/hooks/useApiSimple";

const ErrorPage = ({ error, resetErrorBoundary }) => {
  const [loading, signoutAction] = useApiSimple(signout);
  const navigate = useNavigate();

  const signoutAndReset = () => {
    signoutAction();
    window.location.reload();
  };

  const goHomeAndReset = () => {
    resetErrorBoundary();
    navigate("/");
  };

  return (
    <div className="error-page">
      <h2>페이지에 오류가 발생하였습니다</h2>
      <h3>{error.message}</h3>
      <button onClick={goHomeAndReset}>홈으로</button>
      <button onClick={signoutAndReset}>로그아웃</button>
    </div>
  );
};

export default ErrorPage;
