import { ApiService } from "../../ApiService";
import { authHeader, authClientID } from "../../helpers/authHeader";
import { setAlias } from "./others";

const apiService = new ApiService();

export const getClients = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .getClients(authHeader(getState))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateMainChart = (type) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?qtype=" + type;
    apiService
      .getHateMainChart(
        authHeader(getState),
        authClientID(getState),
        querystring,
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateMainData = (type) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    // let querystring = "?qtype=" + type;
    apiService
      .getHateMainData(
        authHeader(getState),
        authClientID(getState),
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateKeywordData =
  (start_time, end_time, type) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring =
        "?start_time=" + start_time + "&end_time=" + end_time + "&type=" + type;
      apiService
        .getHateKeywordData(
          authHeader(getState),
          authClientID(getState),
          querystring,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const getHateKeywordSubData =
  (start_time, end_time, type, keyword) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring =
        "?start_time=" +
        start_time +
        "&end_time=" +
        end_time +
        "&type=" +
        type +
        "&keyword=" +
        keyword;
      apiService
        .getHateKeywordSubData(
          authHeader(getState),
          authClientID(getState),
          querystring,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const getSocialMainChart = (type, lang) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?qtype=" + type + "&lang=" + lang;
    apiService
      .getSocialMainChart(
        authHeader(getState),
        authClientID(getState),
        querystring,
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSocialMainData = (type, lang) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?qtype=" + type + "&lang=" + lang;
    apiService
      .getSocialMainData(
        authHeader(getState),
        authClientID(getState),
        querystring,
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSocialKeywordData =
  (start_time, end_time, type, lang) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring =
        "?start_time=" +
        start_time +
        "&end_time=" +
        end_time +
        "&type=" +
        type +
        "&lang=" +
        lang;
      apiService
        .getSocialKeywordData(
          authHeader(getState),
          authClientID(getState),
          querystring,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const getSocialKeywordSubData =
  (start_time, end_time, type, keyword, lang) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring =
        "?start_time=" +
        start_time +
        "&end_time=" +
        end_time +
        "&type=" +
        type +
        "&keyword=" +
        keyword +
        "&lang=" +
        lang;
      apiService
        .getSocialKeywordSubData(
          authHeader(getState),
          authClientID(getState),
          querystring,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const getHateList = (queryOptions) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring =
      "?status=" +
      queryOptions.status +
      "&page=" +
      queryOptions.page +
      "&limit=" +
      queryOptions.limit;
    queryOptions.keyword && (querystring += "&keyword=" + queryOptions.keyword);
    queryOptions.site && (querystring += "&site_name=" + queryOptions.site);
    queryOptions.user && (querystring += "&has_username=" + queryOptions.user);
    queryOptions.start &&
      (querystring += "&start_time=" + queryOptions.start.format("YYYY-MM-DD"));
    queryOptions.end &&
      (querystring += "&end_time=" + queryOptions.end.format("YYYY-MM-DD"));
    apiService
      .getHateList(authHeader(getState), authClientID(getState), querystring)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateDetail = (hateObj) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?object_type=" + hateObj.type;
    apiService
      .getHateDetail(authHeader(getState), hateObj.id, querystring)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const putHateList =
  (status, list, queryOptions) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring = "?target_status=" + status;
      querystring +=
        "&status=" +
        queryOptions.status +
        "&page=" +
        queryOptions.page +
        "&limit=" +
        queryOptions.limit;
      queryOptions.keyword &&
        (querystring += "&keyword=" + queryOptions.keyword);
      queryOptions.site && (querystring += "&site_name=" + queryOptions.site);
      queryOptions.user &&
        (querystring += "&has_username=" + queryOptions.user);
      queryOptions.start &&
        (querystring +=
          "&start_time=" + queryOptions.start.format("YYYY-MM-DD"));
      queryOptions.end &&
        (querystring += "&end_time=" + queryOptions.end.format("YYYY-MM-DD"));
      apiService
        .putHateList(
          authHeader(getState),
          authClientID(getState),
          list,
          querystring,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const getHateListNum = (queryOptions) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?stat=";
    queryOptions.keyword && (querystring += "&keyword=" + queryOptions.keyword);
    queryOptions.site && (querystring += "&site_name=" + queryOptions.site);
    queryOptions.user && (querystring += "&has_username=" + queryOptions.user);
    queryOptions.start &&
      (querystring += "&start_time=" + queryOptions.start.format("YYYY-MM-DD"));
    queryOptions.end &&
      (querystring += "&end_time=" + queryOptions.end.format("YYYY-MM-DD"));
    apiService
      .getHateListNum(authHeader(getState), authClientID(getState), querystring)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConfigBoard = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .getConfigBoard(authHeader(getState), authClientID(getState))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postConfigBoard = (list) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .postConfigBoard(authHeader(getState), authClientID(getState), list)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const getConfigKeyword = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .getConfigKeyword(authHeader(getState), authClientID(getState))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postConfigKeyword = (list) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .postConfigKeyword(authHeader(getState), authClientID(getState), list)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateCalendar =
  (start_time, end_time) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let querystring = "?start_time=" + start_time + "&end_time=" + end_time;
      apiService
        .getHateCalendar(
          authHeader(getState),
          authClientID(getState),
          querystring,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const getDataCalendar = (type) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .getDataCalendar(authHeader(getState), authClientID(getState))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postDataCalendar = (list) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .postDataCalendar(authHeader(getState), authClientID(getState), list)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateCSV = (queryOptions) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?status=" + queryOptions.status;
    queryOptions.keyword && (querystring += "&keyword=" + queryOptions.keyword);
    queryOptions.site && (querystring += "&site_name=" + queryOptions.site);
    queryOptions.user && (querystring += "&has_username=" + queryOptions.user);
    queryOptions.start &&
      (querystring += "&start_time=" + queryOptions.start.format("YYYY-MM-DD"));
    queryOptions.end &&
      (querystring += "&end_time=" + queryOptions.end.format("YYYY-MM-DD"));
    let header = authHeader(getState);
    header.responseType = "blob";
    apiService
      .getHateCSV(header, authClientID(getState), querystring)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;

        const disposition = res.headers["content-disposition"];
        const fileName = decodeURI(
          disposition
            .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
            .replace(/['"]/g, ""),
        );
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getHateOptions = (status) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let querystring = "?simple=1&all=1&status=" + status;
    apiService
      .getHateOptions(authHeader(getState), authClientID(getState), querystring)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ignorePost = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    data.client_id = authClientID(getState);
    apiService
      .ignorePost(authHeader(getState), data)
      .then((res) => {
        resolve(res.data);
        window.alert("api 성공");
      })
      .catch((err) => {
        reject(err);
        window.alert(err?.response?.data?.detail);
      });
  });
};

export const postHateCheck = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .postHateCheck(authHeader(getState))
      .then((res) => {
        resolve(res.data);
        window.alert("트리거 전달 완료");
      })
      .catch((err) => {
        reject(err);
        window.alert(err?.response?.data?.detail);
      });
  });
};


export const postDailyAnalysis = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiService
      .postDailyAnalysis(authHeader(getState), authClientID(getState))
      .then((res) => {
        resolve(res.data);
        window.alert("트리거 전달 완료");
      })
      .catch((err) => {
        reject(err);
        window.alert(err?.response?.data?.detail);
      });
  });
};


export const getAliasList = () => (dispatch, getState) => {
  let querystring = "?client_id=" + authClientID(getState);
  return new Promise((resolve, reject) => {
    apiService
      .getAliasList(authHeader(getState), querystring)
      .then((res) => {
        dispatch(setAlias(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  })
}