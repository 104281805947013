import React from "react";

import "../user/user.css";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../redux/actions/others";
import ProfileCard from "../../components/status-card/profile-card";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showProfile = (data) => {
    dispatch(setProfile(data));
    localStorage.setItem("lastArtist", data.name);
    navigate("/admin/hate/main");
  };
  const profiles = useSelector((state) => state.Others.profiles.data ?? []);

  return (
    <div style={{ paddingTop: "20px" }}>
      <div className="d-row">
        {profiles.map((data) => (
          <ProfileCard
            image={data.image}
            name={data.name}
            group={data.group}
            id={data.id}
            key={data.id}
            onClick={showProfile}
          />
        ))}
      </div>
    </div>
  );
};

export default Profile;
