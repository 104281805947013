import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";

import MailTable from "../../components/mail/mailList";
import "./listpage.css";
import {
  getHateList,
  getHateListNum,
  putHateList,
  getHateCSV,
  getHateOptions,
  postHateCheck,
  getAliasList
} from "../../redux/actions/api_user";
import useApiSimple from "../../components/hooks/useApiSimple";
import useApi from "../../components/hooks/useApi";
import LoadFrame from "../../components/loading/LoadFrame";
import TextLoad from "../../components/loading/TextLoad";
import { generateRange } from "../../function";
import RenderWrapper from "../../components/display/renderWrapper";
import { siteKorMap } from "../../config";
import ApiErrorFrame from "../../components/error/ApiErrorFrame";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const ListPage = () => {
  const auth = useSelector((state) => state.auth);
  const statusNew = auth.user.user_service.use_confirm ? "DR" : "UC";

  const [loading, dataList, error, getList] = useApi(getHateList);
  const pageLimit = 10;
  const [loading2, dataNum, error2, getNum] = useApi(getHateListNum);
  const [loading3, dataList2, error3, putList] = useApi(putHateList);
  const [loading4, getCSV] = useApiSimple(getHateCSV, false);
  const [loading5, hateOptions, error5, getHateOption] = useApi(getHateOptions);
  const [loading6, postHateCheckFunc] = useApiSimple(postHateCheck);
  const [loading7, getAlias] = useApiSimple(getAliasList);

  const [queryOptions, setQuery] = useState({
    page: 1,
    status: statusNew,
    limit: 10,
    keyword: null,
    site: null,
    user: null,
    start: dayjs().subtract(1, "year").add(1, "day"),
    end: dayjs(),
  });
  const [hateList, setData] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [largePage, setLargePage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckList] = useState([]);
  const [submittedItems, setSubmittedItems] = useState({});
  const [keywordStaging, setKeywordStaging] = useState("");
  const [numbers, setNumbers] = useState({
    TR: 0,
    DE: 0,
    DR: 0,
    RE: 0,
    RR: 0,
    UC: 0,
    CF: 0,
    DF: 0,
  });
  const [filterShow, setFilterShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [filterStaging, setFilterStaging] = useState({});
  const [dateValueStaging, setDateValueStaging] = useState({
    start: dayjs().subtract(7, "month").add(1, "day"),
    end: dayjs(),
  });

  const selectPage = (page) => {
    if (page !== queryOptions.page) {
      setQuery({
        ...queryOptions,
        page: page,
      });
      setFilterShow(false);
    }
  };

  const selectLimit = (limit) => {
    if (queryOptions.limit !== limit) {
      setQuery({
        ...queryOptions,
        limit: limit,
        page: 1,
      });
      setLargePage(0);
      setFilterShow(false);
    }
  };

  const selectLargePage = (largePage) => {
    let page = largePage * pageLimit + 1;
    setLargePage(largePage);
    selectPage(page);
  };

  const selectStatus = (status) => {
    if (status !== queryOptions.status) {
      setQuery({
        ...queryOptions,
        status: status,
        keyword: null,
        page: 1,
      });
      setLargePage(0);
      setFilterShow(false);
    }
  };

  const allCheckedHandler = (isChecked) => {
    if (isChecked) {
      setCheckList(hateList.map((item) => [item.id, item.type]));
      setIsAllChecked(true);
    } else {
      setCheckList([]);
      setIsAllChecked(false);
    }
  };

  const registerKeyword = () => {
    if (queryOptions.keyword !== keywordStaging) {
      setQuery({
        ...queryOptions,
        keyword: keywordStaging,
        page: 1,
      });
      setLargePage(0);
    }
    setKeywordStaging("");
    setFilterShow(false);
  };

  const deleteKeyword = () => {
    setQuery({
      ...queryOptions,
      keyword: null,
      page: 1,
    });
    setLargePage(0);
    setFilterShow(false);
  };

  const handleLargePage = (direction) => {
    if (direction === -1 && largePage > 0) {
      selectLargePage(largePage - 1);
    } else if (direction === 1 && largePage < Math.floor(maxPage / pageLimit)) {
      selectLargePage(largePage + 1);
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      registerKeyword();
    }
  };

  const genPages = () => {
    let first_page = largePage * pageLimit + 1;
    let last_page = Math.min((largePage + 1) * pageLimit, maxPage);

    return generateRange(first_page, last_page).map((page_no) => (
      <button
        key={page_no}
        className={`table__pagination-item ${queryOptions.page === page_no ? "active" : ""}`}
        onClick={() => selectPage(page_no)}
      >
        {page_no}
      </button>
    ));
  };

  const GenOptions = () => {
    const { showBoundary } = useErrorBoundary();
    if (error5) {
      showBoundary(error5);
    }
    if (options) {
      let returnArray = [
        <Dropdown.Item
          key={1000}
          eventKey={"선택없음"}
          onClick={() => setFilterStaging({ ...filterStaging, site: null })}
        >
          선택없음
        </Dropdown.Item>,
      ];
      Object.entries(options).forEach((el, index) => {
        returnArray.push(
          <Dropdown.Item
            key={index}
            eventKey={el[0]}
            onClick={() => setFilterStaging({ ...filterStaging, site: el[0] })}
          >
            {siteKorMap[el[0]]} : {el[1]}
          </Dropdown.Item>,
        );
      });

      return (
        <DropdownButton
          id="dropdown-basic-button-filter"
          className="list-menu-button"
          title={filterStaging.site || "사이트 선택"}
        >
          {returnArray}
        </DropdownButton>
      );
    } else {
      return null;
    }
  };

  const handleToggle = () => {
    if (!filterShow) {
      getHateOption(queryOptions.status);
    }
    setFilterShow(!filterShow);
  };

  const confirmFilter = () => {
    setQuery({
      ...queryOptions,
      ...filterStaging,
      page: 1,
    });
    handleToggle();
    setLargePage(0);
  };

  const clearSiteFilter = () => {
    setQuery({
      ...queryOptions,
      page: 1,
      site: null,
    });
    setFilterStaging({
      ...filterStaging,
      site: null,
    });
    setLargePage(0);
  };

  const clearUserFilter = () => {
    setQuery({
      ...queryOptions,
      page: 1,
      user: null,
    });
    setFilterStaging({
      ...filterStaging,
      user: null,
    });
    setLargePage(0);
  };

  const filterPopOver = (
    <Popover>
      <Popover.Body>
        <div className="filter-box">
          <ErrorBoundary FallbackComponent={ApiErrorFrame}>
            <div className="filter-box-site">
              <GenOptions />
            </div>
            <div className="filter-box-user">
              <div>로그인한 유저만</div>
              <div>
                <input
                  type="checkbox"
                  checked={filterStaging.user || false}
                  className="filter-user-check"
                  defaultValue={false}
                  onChange={(e) =>
                    setFilterStaging({
                      ...filterStaging,
                      user: !filterStaging.user,
                    })
                  }
                />
              </div>
            </div>
          </ErrorBoundary>
        </div>
        <div className="filter-buttons">
          <div
            onClick={handleToggle}
            onKeyDown={handleToggle}
            role="button"
            tabIndex={0}
          >
            취소
          </div>
          <div
            onClick={confirmFilter}
            onKeyDown={confirmFilter}
            role="button"
            tabIndex={0}
          >
            확인
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (dataList?.data) {
      setData(dataList.data.current_page_data);
      setMaxPage(dataList.data.max_page);
      // if (queryOptions.page > dataList.data.max_page) {
      //   // this case will not happen
      //   console.log('maxpage over.')
      //   setPage(dataList.data.max_page);
      //   setLargePage(calculateLargePage(dataList.data.max_page));
      // }
      setCheckList([]);
      setIsAllChecked(false);
    }
  }, [dataList]);

  useEffect(() => {
    getNum(queryOptions);
    if (auth.user.role === "admin"){
      getAlias();
    }
  }, []);

  //댓글 위치 변경시 리셋
  useEffect(() => {
    if (dataList2) {
      setQuery({
        ...queryOptions,
        page: Math.min(dataList2.data.max_page, queryOptions.page),
      });
      getNum(queryOptions);
    }
  }, [dataList2]);

  useEffect(() => {
    if (dataNum?.data) {
      setNumbers(dataNum.data);
    }
  }, [dataNum]);

  useEffect(() => {
    if (hateOptions) {
      setOptions(hateOptions.data);
    }
  }, [hateOptions]);

  useEffect(() => {
    getList(queryOptions);
  }, [queryOptions]);

  useEffect(() => {
    getNum(queryOptions);
  }, [
    queryOptions.keyword,
    queryOptions.start,
    queryOptions.end,
    queryOptions.site,
    queryOptions.user,
  ]);

  const handlePutList = (status) => {
    setSubmittedItems({
      items: checkedItems,
      options: queryOptions,
      status: status,
    });
    putList(status, checkedItems, queryOptions);
  };

  const handleRevert = () => {
    if (window.confirm("마지막 작업을 실행취소하시겠습니까?")) {
      putList(submittedItems.options.status, submittedItems.items, {
        ...submittedItems.options,
        status: submittedItems.status,
      });
      setSubmittedItems({});
    }
  };

  return (
    <div className="listpage">
      <div className="d-row frame">
        <div className="col-2 mail-side">
          <div className="list">
            <RenderWrapper condition={auth.user.role === "admin"}>
              <div
                onClick={() => selectStatus("UC")}
                onKeyDown={() => selectStatus("UC")}
                className={`element ${queryOptions.status == "UC" && "clicked"}`}
                role="button"
                tabIndex={0}
              >
                <div>분류 대기중</div>
                {loading2 ? <TextLoad /> : <span>{numbers["UC"]}</span>}
              </div>
            </RenderWrapper>
            <div
              onClick={() => selectStatus(statusNew)}
              onKeyDown={() => selectStatus(statusNew)}
              className={`element ${queryOptions.status == statusNew && "clicked"}`}
              role="button"
              tabIndex={0}
            >
              <div>악플함</div>
              {loading2 ? <TextLoad /> : <span>{numbers[statusNew]}</span>}
            </div>
            <div
              onClick={() => selectStatus("DE")}
              onKeyDown={() => selectStatus("DE")}
              className={`element ${queryOptions.status == "DE" && "clicked"}`}
              role="button"
              tabIndex={0}
            >
              <div>삭제 완료 목록</div>
              {loading2 ? <TextLoad /> : <span>{numbers["DE"]}</span>}
            </div>
            <div
              onClick={() => selectStatus("DF")}
              onKeyDown={() => selectStatus("DF")}
              className={`element ${queryOptions.status == "DF" && "clicked"}`}
              role="button"
              tabIndex={0}
            >
              <div>삭제 실패 목록</div>
              {loading2 ? <TextLoad /> : <span>{numbers["DF"]}</span>}
            </div>
            <RenderWrapper condition={auth.user.role === "admin"}>
              <div
                onClick={() => selectStatus("TR")}
                onKeyDown={() => selectStatus("TR")}
                className={`element ${queryOptions.status == "TR" && "clicked"}`}
                role="button"
                tabIndex={0}
              >
                <div>휴지통</div>
                {loading2 ? <TextLoad /> : <span>{numbers["TR"]}</span>}
              </div>
            </RenderWrapper>
          </div>
        </div>
        <div className="col-10 mail-main">
          <div className="d-row mail-top1">
            <div className="mail-title">새 목록</div>
            <div className="d-row middle-item">
              <div className="searchbar">
                <input
                  type="text"
                  className="inputBox"
                  placeholder="악플 검색"
                  onChange={(e) => setKeywordStaging(e.target.value)}
                  onKeyPress={handleEnterKey}
                  value={keywordStaging}
                />
                <button
                  type="submit"
                  className="searchButton"
                  onClick={registerKeyword}
                >
                  <i className="bx bx-search"></i>
                </button>
              </div>
              <div className="menu">
                {/* <i className="bx bx-menu" /> */}
                <DropdownButton
                  id="dropdown-basic-button"
                  className="list-menu-button"
                  title="보기"
                >
                  <Dropdown.Item eventKey="10" onClick={() => selectLimit(10)}>
                    10개씩 보기
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="50" onClick={() => selectLimit(50)}>
                    50개씩 보기
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="100"
                    onClick={() => selectLimit(100)}
                  >
                    100개씩 보기
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="500"
                    onClick={() => selectLimit(500)}
                  >
                    500개씩 보기
                  </Dropdown.Item>
                </DropdownButton>
              </div>
              <OverlayTrigger
                rootClose
                onHide={handleToggle}
                className="menu"
                trigger={null}
                show={filterShow}
                placement="right"
                overlay={filterPopOver}
              >
                <div
                  className="menu list-menu-filter"
                  onClick={() => {
                    handleToggle();
                  }}
                  onKeyDown={() => {
                    handleToggle();
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <span>필터</span>
                  <span className="material-icons-outlined">settings</span>
                </div>
              </OverlayTrigger>
            </div>
            <div className="listpage-timestamp">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="시작 시간"
                  value={dateValueStaging.start}
                  format="YYYY / MM / DD"
                  onChange={(newValue) =>
                    setDateValueStaging({
                      ...dateValueStaging,
                      start: newValue,
                    })
                  }
                  sx={{ width: 180}}
                />
                <DatePicker
                  label="끝 시간"
                  value={dateValueStaging.end}
                  format="YYYY / MM / DD"
                  onChange={(newValue) =>
                    setDateValueStaging({ ...dateValueStaging, end: newValue })
                  }
                  sx={{ width: 180 }}
                />
              </LocalizationProvider>
              <div className="buttons">
                <button
                  onClick={() =>
                    setQuery({
                      ...queryOptions,
                      start: dateValueStaging.start,
                      end: dateValueStaging.end,
                    })
                  }
                  className="small-button"
                >
                  적용
                </button>
              </div>
            </div>
          </div>
          <div className="d-row mail-top2">
            <input
              type="checkbox"
              checked={isAllChecked}
              className="checkbox"
              onChange={(e) => allCheckedHandler(!isAllChecked)}
            />
            <div className="buttons">
              <button
                onClick={() => handlePutList("TR")}
                onKeyDown={() => handlePutList("TR")}
                className="small-button"
              >
                지우기
              </button>
              <RenderWrapper condition={auth.user.role === "admin"}>
                <div className="row-line" />
                <button
                  onClick={() => handlePutList("DR")}
                  onKeyDown={() => handlePutList("DR")}
                  className="small-button"
                >
                  악플함으로
                </button>
                <div className="row-line" />
                <button
                  onClick={() => handlePutList("DE")}
                  onKeyDown={() => handlePutList("DE")}
                  className="small-button"
                >
                  삭제 완료로
                </button>
                <div className="row-line" />
                <button
                  onClick={() => handlePutList("DF")}
                  onKeyDown={() => handlePutList("DF")}
                  className="small-button"
                >
                  삭제 실패로
                </button>
                <div className="row-line" />
                <button
                  onClick={handleRevert}
                  onKeyDown={handleRevert}
                  className={
                    "small-button" + (submittedItems.status ? "" : " disabled")
                  }
                  disabled={!submittedItems.status}
                >
                  실행 취소
                </button>
              </RenderWrapper>
            </div>

            <div className="buttons">
              <button
                className="small-button"
                onClick={() => getCSV(queryOptions)}
              >
                현재 목록 엑셀 다운로드
              </button>
              {loading4 && (
                <span>
                  <TextLoad />
                </span>
              )}
            </div>
            <div className="buttons">
              <RenderWrapper
                condition={
                  auth.user.role === "admin" && queryOptions.status === "DR"
                }
              >
                <button
                  onClick={() => postHateCheckFunc()}
                  onKeyDown={() => postHateCheckFunc()}
                  className="small-button"
                >
                  자동 삭제 트리거
                </button>
              </RenderWrapper>
            </div>
            {queryOptions.site ? (
              <div className="filter-current">
                <span>사이트 필터링 : {siteKorMap[queryOptions.site]}</span>
                <span
                  className="material-icons-outlined"
                  onClick={clearSiteFilter}
                  onKeyDown={clearUserFilter}
                  role="button"
                  tabIndex={0}
                >
                  close
                </span>
              </div>
            ) : null}
            {queryOptions.user ? (
              <div className="filter-current">
                <span>로그인 유저 필터링 : ON</span>
                <span
                  className="material-icons-outlined"
                  onClick={clearUserFilter}
                  onKeyDown={clearUserFilter}
                  role="button"
                  tabIndex={0}
                >
                  close
                </span>
              </div>
            ) : null}

            {queryOptions.keyword && (
              <div className="d-row rightest-item">
                <span className="text">{`검색어: ${queryOptions.keyword}`}</span>

                <button
                  className="table__pagination-item"
                  onClick={deleteKeyword}
                >
                  <i className="bx bx-reset"></i>
                </button>
              </div>
            )}
          </div>

          <div className="mail-content">
            <ErrorBoundary FallbackComponent={ApiErrorFrame}>
              <LoadFrame loading={loading} error={error}>
                <MailTable
                  dataShow={hateList}
                  listState={[checkedItems, setCheckList]}
                  allCheckState={[isAllChecked, setIsAllChecked]}
                />

                <div className="table__pagination">
                  <button
                    className="table__pagination-item"
                    onClick={() => {
                      selectLargePage(0);
                    }}
                    disabled={largePage === 0}
                  >
                    <i className="bx bx-chevrons-left" />
                  </button>

                  <button
                    className="table__pagination-item"
                    onClick={() => {
                      handleLargePage(-1);
                    }}
                    disabled={largePage === 0}
                  >
                    <i className="bx bx-chevron-left" />
                  </button>

                  {genPages()}
                  <button
                    className="table__pagination-item"
                    onClick={() => {
                      handleLargePage(1);
                    }}
                    disabled={
                      largePage ===
                      Math.floor(maxPage / pageLimit) -
                        (maxPage % 10 === 0 ? 1 : 0)
                    }
                  >
                    <i className="bx bx-chevron-right" />
                  </button>

                  <button
                    className="table__pagination-item"
                    onClick={() => {
                      selectLargePage(Math.floor(maxPage / pageLimit));
                    }}
                    disabled={
                      largePage ===
                      Math.floor(maxPage / pageLimit) -
                        (maxPage % 10 === 0 ? 1 : 0)
                    }
                  >
                    <i className="bx bx-chevrons-right" />
                  </button>
                </div>
              </LoadFrame>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListPage;
