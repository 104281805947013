import { addDays } from "date-fns";
import { act } from "react-dom/test-utils";
import { compose } from "redux";
import dateString from "../../components/datepick/dateString";

const Others = (
  state = {
    post: {},
    modal: false,
    dates: {
      end: dateString(new Date()),
      start: dateString(addDays(new Date(), -7)),
      type: "Today",
    },
    lang: "kr",
    id: {},
    profile: {},
    profiles: {},
    alias: [],
    schedule: [
      {
        id: 0,
        title: "All Day Event very long title",
        allDay: true,
        start: new Date(2020, 5, 3).getTime(),
        end: new Date(2020, 5, 3).getTime(),
      },
      {
        id: 1,
        title: "Long Event",
        start: new Date(2021, 6, 7).getTime(),
        end: new Date(2021, 10, 10).getTime(),
      },

      {
        id: 2,
        title: "DTS STARTS",
        start: new Date(2016, 2, 13).getTime(),
        end: new Date(2016, 2, 20).getTime(),
      },

      {
        id: 3,
        title: "DTS ENDS",
        start: new Date(2016, 10, 6).getTime(),
        end: new Date(2016, 10, 13).getTime(),
      },
    ],
    checkeditems: [],
  },
  action,
) => {
  switch (action.type) {
    case "SET_POST":
      return {
        ...state,
        modal: true,
        post: action.payload,
      };
    case "DELETE_POST":
      return {
        ...state,
        modal: false,
        post: {},
      };
    case "SET_DATES":
      return {
        ...state,
        dates: action.payload,
      };
    case "SET_LANG":
      return {
        ...state,
        lang: action.payload,
      };
    case "SET_PROFILE":
      return {
        ...state,
        profile: action.payload,
      };
    case "SET_PROFILES":
      return {
        ...state,
        profiles: action.payload,
      };
    case "CLEAR_PROFILE":
      return {
        ...state,
        profile: {},
        profiles: {},
      };
    case "SET_SCHEDULE":
      return {
        ...state,
        schedule: action.payload,
      };
    case "SET_CHECK":
      return {
        ...state,
        checkedItems: action.payload,
      };

    case "SET_ALIAS":
      return {
        ...state,
        alias: action.payload,
      };

    default:
      return state;
  }
};

export default Others;
