import React from "react";
import LoadFrame from "../loading/LoadFrame";
import "./status-card.css";
import "./statuscard.css";
import StatusCard from "./StatusCard";

const AllStatusCard = (props) => {
  if (props.social) {
    return (
      <>
        <div className="col-4 left">
          <StatusCard
            title="전체 댓글 수"
            value={props.countData?.total}
            diff_value={props.total_comments_diff}
            is_loading={props.loading}
            error={props.error}
          />
        </div>
        <div className="col-4">
          <StatusCard
            title="긍정적 언급 수"
            value={props.countData?.positive}
            diff_value={props.positive_comments_diff}
            is_loading={props.loading}
            error={props.error}
          />
        </div>
        <div className="col-4 right">
          <StatusCard
            title="긍정 비율"
            value={props.positive_ratio?.toFixed(4)}
            diff_value={props.positive_ratio_diff}
            is_loading={props.loading}
            error={props.error}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="col-4 left">
          <StatusCard
            title="일간 악플 수"
            value={props.daily_count}
            period={props.daily_date}
            is_loading={props.loading}
            error={props.error}
          />
        </div>
        <div className="col-4">
          <StatusCard
            title="주간 악플 수"
            value={props.weekly_count}
            period={props.weekly_date}
            is_loading={props.loading}
            error={props.error}
          />
        </div>
        <div className="col-4 right">
          <StatusCard
            title="월간 악플 수"
            value={props.monthly_count}
            period={props.monthly_date}
            is_loading={props.loading}
            error={props.error}
          />
        </div>
      </>
    )
  }
};

export default AllStatusCard;
