import Chart from "react-apexcharts";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { dateString2 } from "../../datepick/dateString";
import { de } from "date-fns/locale";
import { siteKorMap } from "../../../config";

const ZoomOptions = (small_size, handleSelect, series, selectedIndex) => ({
  options: {
    chart: {
      toolbar: {
        show: false,
      },
      background: "transparent",
      type: "line",
      stacked: false,
      zoom: {
        type: "x",
        enabled: false,
        autoScaleYaxis: true,
      },
      events: {
        legendClick: function (chartContext, seriesIndex) {
          handleSelect(seriesIndex);
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#993BF6", "#5443FF", "#3178FF", "#3AEBFF", "#38FFA9", "#ADFF31"],
    markers: {
      size: 2,
    },
    stroke: {
      curve: "straight",
    },
    yaxis: {
      labels: {
        style: {
          fontSize: small_size ? "14px" : "16px",
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      fontSize: small_size ? "14px" : "14px",
      markers: {
        width: small_size ? 16 : 21,
        height: small_size ? 16 : 21,
        radius: small_size ? 4 : 5,
        offsetX: -5,
        fillColors: series.map((_, idx) =>
          idx === selectedIndex ? undefined : ["#993BF6", "#5443FF", "#3178FF", "#3AEBFF", "#38FFA9", "#ADFF31"][idx]+'35' // 비활성화 색상 흐리게
        )
      },
      itemMargin: {
        horizontal: small_size ? 15 : 20,
        vertical: 20
      },
      onItemClick: {
        toggleDataSeries: false // 기본 클릭 동작 막기
      },
      labels:{
        colors: series.map((_, idx) =>
          idx === selectedIndex ? '#000000' : '#00000050' // 비활성화 색상 흐리게
        )
      }
    },
    // states: {
    //   active: {
    //     filter: {
    //       type: "none", // 기본 필터 제거
    //     },
    //     style: {
    //       fontWeight: "bold", // 선택된 Legend의 글씨를 굵게
    //     },
    //   },
    //   inactive: {
    //     filter: {
    //       type: "opacity",
    //       value: 0.5, // 선택되지 않은 Legend의 투명도를 낮춤
    //     },
    //     style: {
    //       fontWeight: "300", // 선택되지 않은 Legend의 글씨를 얇게
    //     },
    //   },
    // },
  },
});
const compareDate = (timestamp1, start, end) => {
  const date1 = new Date(timestamp1);
  const date2 = new Date(start);

  return (
    date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth()
  );
};
const ZoomChart = ({ series, resize }) => {
  const themeReducer = useSelector((state) => state.ThemeReducer.mode);
  const schedule = useSelector((state) => state.Others.schedule);

  const [targetData, setTargetData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setTargetData(series.data.map((el) => {
      return {
        name: siteKorMap[el.name],
        data: el.data,
      };
    }));
  }, [series]);

  const updatedSeries = targetData.map((s, idx) => ({
    ...s,
    data: idx === selectedIndex ? s.data : s.data.map(() => null) // 선택되지 않은 시리즈 숨기기
  }));

  const handleSelect = (index) => {
    setSelectedIndex(index);
  }
  
  return (
    <Chart
      options={{
        ...ZoomOptions(resize < 1280, handleSelect, targetData, selectedIndex).options,
        theme: { mode: themeReducer === "theme-mode-dark" ? "dark" : "light" },
        tooltip: {
          enabled: true,
          x: {
            formatter: (val) => {
              let string = `<div>${series.category[val - 1]}</div>`;
              schedule.map((item) => {
                if (
                  item.start >= series.range[val - 1][0] &&
                  item.start <= series.range[val - 1][1]
                ) {
                  string = string + `<div>${item.title}</div>`;
                }
              });
              return string;
            },
          },
        },
        xaxis: {
          categories: series.category,
          tickAmount: 7,
          type: "category",
          labels: {
            style: {
              fontSize: resize < 1280 ? "14px" : "16px",
            },
          },
        }
      }}
      data={[]}
      // series={targetData}
      series={updatedSeries}
      type="line"
      height="380"
    />
  );
};

export default ZoomChart;
