import React from "react";

const ApiErrorFrame = ({ error, resetErrorBoundary }) => {
  return (
    <div className="error-frame">
      서버 오류가 발생하였습니다 : {error.message}
    </div>
  );
};

export default ApiErrorFrame;
