import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import "../user/user.css";

import AdminRoute from "./adminRoute";
import { LoadElement } from "../../components/loading/LoadFrame";
import { setProfile } from "../../redux/actions/others";

const AdminPage = () => {
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.Others.profiles);
  const profile = useSelector((state) => state.Others.profile);

  useEffect(() => {
    if (!profile.id && profiles.data) {
      const lastArtistName = localStorage.getItem("lastArtist");
      if (lastArtistName) {
        profiles.data.forEach((profileElement) => {
          if (profileElement.name === lastArtistName) {
            dispatch(setProfile(profileElement));
          }
        });
      }
    }
  }, [profile, profiles]);

  return profile.id ? (
    <Routes>
      <Route path="*" element={<AdminRoute />} />
    </Routes>
  ) : (
    <LoadElement />
  );
};

export default AdminPage;
