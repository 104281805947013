import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as RiIcons from "react-icons/ri";

import { Link, useLocation, useNavigate } from "react-router-dom";

import "./sidebar.css";

import logo from "../../assets/images/logo.png";

import SidebarData from "./SidebarItem";

const SubProfile = ({ item }) => {
  return (
    <>
      <div className="sidebar__profile">
        <img src={item.image} alt="profile" />
        <div>{item.name}</div>
      </div>
    </>
  );
};

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  const location = useLocation();
  const pathPrefix = "/admin";

  return (
    <>
      {item.subNav ? (
        <div
          onClick={item.subNav && showSubnav}
          onKeyDown={item.subNav && showSubnav}
          className="sidebar__item"
          role="button"
          tabIndex={0}
        >
          <div className={`sidebar__item-inner ${subnav ? "click" : ""}`}>
            {item.icon}
            <span>{item.title}</span>
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
                ? item.iconClosed
                : null}
          </div>
        </div>
      ) : !item.dev ? (
        <Link to={pathPrefix + item.path} onClick={item.subNav && showSubnav}>
          <div className="sidebar__item">
            <div className={`sidebar__item-inner ${subnav ? "click" : ""}`}>
              {item.icon}
              <span>{item.title}</span>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                  ? item.iconClosed
                  : null}
            </div>
          </div>
        </Link>
      ) : (
        <div className="sidebar__item">
          <div
            className={`sidebar__item-inner ${subnav ? "click" : ""}`}
            onClick={() => {
              window.alert("개발 테스트중입니다!");
            }}
            onKeyDown={() => {
              window.alert("개발 테스트중입니다!");
            }}
            role="button"
            tabIndex={0}
          >
            {item.icon}
            <span>{item.title}</span>
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
                ? item.iconClosed
                : null}
          </div>
        </div>
      )}
      {subnav &&
        item.subNav.map((element, index) => {
          if (element.dev) {
            return (
              <div
                className={
                  "sidebar__item sub_item" +
                  (pathPrefix + element.path == location.pathname
                    ? " current_item"
                    : "")
                }
                onClick={() => {
                  window.alert("개발 테스트중입니다!");
                }}
                onKeyDown={() => {
                  window.alert("개발 테스트중입니다!");
                }}
                key={index}
                role="button"
                tabIndex={0}
              >
                <div className="sidebar__item-inner">
                  <span>{element.title}</span>
                </div>
              </div>
            );
          } else {
            return (
              <Link to={pathPrefix + element.path} key={index}>
                <div
                  className={
                    "sidebar__item sub_item" +
                    (pathPrefix + element.path == location.pathname
                      ? " current_item"
                      : "")
                  }
                >
                  <div className="sidebar__item-inner">
                    <span>{element.title}</span>
                  </div>
                </div>
              </Link>
            );
          }
        })}
    </>
  );
};

const Sidebar = () => {
  const profile = useSelector((state) => state.Others.profile);
  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img src={logo} alt="logo" />
      </div>
      <SubProfile item={profile} />
      {SidebarData.map((item, index) => {
        return <SubMenu item={item} key={index} />;
      })}
      {/* <button onClick={() => temp()}>나가기</button> */}
    </div>
  );
};

export default Sidebar;
