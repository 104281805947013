import React from "react";
import "./profile-card.css";
import { setProfile } from "../../redux/actions/others";

const ProfileCard = ({ image, name, group, id, onClick }) => {
  return (
    <div className="col-4 col-md-6 col-sm-12 profile-container">
      <div className="card">
        <div className="d-row">
          <div className="col-4 profile-thumbnail" style={{"backgroundImage": `url("${image}")`}}>
          </div>

          <div className="col-8 profile-content">
            <h4>{name}</h4>
            <br />
            <button className="profile-button"
              onClick={() =>
                onClick({
                  image: image,
                  name: name,
                  group: group,
                  id: id,
                })
              }
            >
              시작하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
