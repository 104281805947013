import React from "react";
import { Navigate } from "react-router-dom";

const RedirectRoutes = ({ role }) => {
  switch (role) {
    case undefined:
    case "anonymous":
      return <Navigate to="/signin" />;
    case "admin":
    case "customer":
      return <Navigate to="/profile" />;
  }
};

export default RedirectRoutes;
